import { Env } from '@traas/boldor/environments';
import { tpcWebAppConfig } from '@traas/boldor/environments/specific/tpc/environment.tpc-webapp.base';

export const environment: Env = {
    ...tpcWebAppConfig,

    appSchemeUrl: 'tpcclient://boldor.ch/',
    environment: 'prod',
    gatewayUrl: 'https://gw.tpc.ch',
    isDebugMode: false,
    showEnvironmentInformations: false,
    analyticsLoggingEnabled: false,
    analyticsTrackingEnabled: false,
    traceSentry: false,
    logSentry: true,
    analyticsUserIdHashSalt: '------------',
};
