import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { InfiniteScrollListMode, MapMode } from '@traas/boldor/all-models';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { BehaviorSubject, combineLatest, Subject, timer } from 'rxjs';
import { convertToError, LoggingService } from '@traas/common/logging';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, filter, takeUntil, takeWhile, tap, withLatestFrom } from 'rxjs/operators';
import { MapSelectors } from '../../features/home/store/map';
import * as _ from 'lodash';
import { animate, style, transition, trigger } from '@angular/animations';
import { JourneyAdapter } from '../../features/booking/models/journey';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { isPlaceholder, Placeholder } from '../placeholder-list-item/placeholder.model';

@Component({
    selector: 'traas-infinite-scroll-list',
    templateUrl: './infinite-scroll-list.component.html',
    styleUrls: ['./infinite-scroll-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('items', [
            transition(':enter', [
                style({ transform: 'scale(0.8)', opacity: 0.1 }), // initial
                animate('600ms cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })), // final
            ]),
        ]),
    ],
})
export class InfiniteScrollListComponent<T extends JourneyAdapter | Placeholder> implements OnDestroy, OnInit, AfterViewInit {
    @Input({ required: true }) itemTemplate!: TemplateRef<any>;
    @Input({ required: true }) scrollMode!: InfiniteScrollListMode;
    @Input() showSkeletons = true;

    @Output() scrollManually = new EventEmitter<void>();
    itemHeightInPx = this.#calculateItemHeightInPx();

    get skeletonOffset(): number {
        return this.showSkeletons ? 1 : 0;
    }

    @Input() set items(items: T[]) {
        const oldItems = this.#itemsValue;
        this.#itemsValue = items;
        if (this.#isPrepend(items, oldItems)) {
            const currentIdx = items.findIndex((item) => this.#getId(item) === this.#firstVisibleItemId);
            if (currentIdx !== undefined) {
                /* This if is to prevent bug on iOS: when user is scrolling if scrollToIndex is called
                at same moment, view is broken and list disappear because of rendering bug */
                if (this.#$scrolledIndexChange.getValue() === 0) {
                    this.#scrollToIndex(currentIdx);
                }
                this.cdRef.detectChanges();
            }
        }

        this.#newItems$.next();
    }

    get items(): T[] {
        return this.#itemsValue;
    }

    @Output() requestMore = new EventEmitter<T>();
    @Output() requestPrevious = new EventEmitter<T>();
    @Output() newItemsInViewport = new EventEmitter<T[]>();
    viewportBufferSize = 14;

    @ViewChild(CdkVirtualScrollViewport) private viewport: CdkVirtualScrollViewport;
    #$scrolledIndexChange = new BehaviorSubject<number>(0);
    #userIsScrolling$ = new BehaviorSubject<boolean>(false);
    #firstLoading = true;
    #itemsValue: T[] = [];
    #firstVisibleItemId: string | null = null;
    #currentIndexInScrollAtScrollStart = 0;
    readonly #$unsubscribe = new Subject<void>();
    readonly #$hasOutdatedItems = new Subject<void>();
    readonly #newItems$ = new Subject<void>();
    readonly #scrollDispatcher = inject(ScrollDispatcher);

    constructor(
        private readonly cdRef: ChangeDetectorRef,
        private readonly logger: LoggingService,
        private readonly store: Store,
    ) {
        const DEBOUNCE_TIME_IN_MS = 50;

        /**
         * Cet observalbe est construit depuis #newItems$ qui est délcenché à un @Input(). Il faut donc s'y souscrire
         * dans le constructeur pour être sûr de ne pas rater le premier déclenchement.
         */
        this.#newItems$
            .pipe(
                debounceTime(DEBOUNCE_TIME_IN_MS), // This is to prevent too many triggers
                filter(() => !this.#userIsScrolling$.getValue()),
                takeWhile(() => this.scrollMode !== InfiniteScrollListMode.Manual),
            )
            .subscribe(() => {
                this.scrollToFirstFutureItem();
            });
    }

    ngOnInit(): void {
        this.#resizeScrollOnSwitchMapMode();
        const DEBOUNCE_TIME_IN_MS = 50;

        this.#$hasOutdatedItems
            .pipe(
                debounceTime(DEBOUNCE_TIME_IN_MS), // This is to prevent too many triggers
                filter(() => !this.#userIsScrolling$.getValue()),
                takeWhile(() => this.scrollMode !== InfiniteScrollListMode.Manual),
            )
            .subscribe(() => {
                this.scrollToFirstFutureItem();
            });
    }

    ngOnDestroy(): void {
        this.#unsubscribe();
    }

    ngAfterViewInit(): void {
        /**
         * Permet d'écouter les évenements de scroll et de déclencher la souscription uniquement lorsque l'utilisateur a fini de scroller
         * Car nous filtrons pour ne garder que les événements où l'utilisateur n'est pas en train de scroller.
         * De plus, nous vérifions que l'index a changé pour éviter de déclencher inutilement la souscription.
         */
        const AVERAGE_DELAY_TO_NO_SPAM_SCROLL_EVENT = 100;
        combineLatest([this.#scrollDispatcher.scrolled(AVERAGE_DELAY_TO_NO_SPAM_SCROLL_EVENT), this.#userIsScrolling$])
            .pipe(
                filter(([, isScrolling]) => !isScrolling),
                withLatestFrom(this.#$scrolledIndexChange, (context, scrolledIndex) => scrolledIndex),
                takeUntil(this.#$unsubscribe),
                distinctUntilChanged((prevIndex, newIndex) => prevIndex === newIndex),
            )
            .subscribe((scrolledIndex) => {
                const firstVisibleItemIndex = _.clamp(scrolledIndex - this.skeletonOffset, 0, this.items.length - 1);
                this.#firstVisibleItemId = this.items?.length > 0 ? this.#getId(this.items[firstVisibleItemIndex]) : null;

                // Load previous
                if (scrolledIndex === 0 && !this.#firstLoading) {
                    // console.log('requestPrevious', this.items[0]);
                    this.requestPrevious.emit(this.items[0]);
                }

                // Load more
                const indexOfLastRenderedItem = this.viewport.getRenderedRange().end;
                const indexOfLastItem = this.viewport.getDataLength();
                if (indexOfLastItem === indexOfLastRenderedItem) {
                    // console.log('requestMore', _.last(this.items));
                    this.requestMore.emit(_.last(this.items));
                }

                this.newItemsInViewport.emit(this.#getVisibleItems(firstVisibleItemIndex));
                this.#firstLoading = false;
            });
    }

    scrollIndexChange(index: number): void {
        // console.log('INDEX CHANGE', index);
        this.#$scrolledIndexChange.next(index);
    }

    onUserScrollStart(): void {
        // console.log('onUserScrollStart -> current index', this.#$scrolledIndexChange.getValue());
        this.#currentIndexInScrollAtScrollStart = this.#$scrolledIndexChange.getValue();
        this.#userIsScrolling$.next(true);
    }

    onUserScrollEnd(): void {
        // console.log('onUserScrollEnd -> new index', this.#$scrolledIndexChange.getValue());
        // If user has scroll on new index position in list, so we estimate than he has really scrolled.
        if (this.#currentIndexInScrollAtScrollStart !== this.#$scrolledIndexChange.getValue()) {
            this.scrollManually.emit();
        }
        this.#userIsScrolling$.next(false);
    }

    scrollToFirstFutureItem(): void {
        try {
            const futureItemIndex = this.items.findIndex((item) => !isPlaceholder(item) && !item.isOutdated());
            if (futureItemIndex < 0) {
                return;
            }
            this.#scrollToIndex(futureItemIndex + this.skeletonOffset);
        } catch (error) {
            this.logger.logError(
                new TechnicalError(
                    'Error while scrolling to first future journey',
                    ErrorCodes.Technical.ScrollToFirstFutureJourney,
                    convertToError(error),
                ),
            );
        }
    }

    identify(index: number, item: T): string {
        return getId(item);
    }

    hasOutdatedItem(): void {
        this.#$hasOutdatedItems.next();
    }

    #getVisibleItems(start: number): T[] {
        if (this.items?.length > 0) {
            const end = start + this.#getNumberOfItemsVisibleInScroll();
            return this.items.slice(start, end);
        }
        return [];
    }

    #resizeScrollOnSwitchMapMode(): void {
        this.store
            .select(MapSelectors.getMapMode)
            .pipe(
                filter((mapMode) => mapMode === MapMode.Small),
                takeUntil(this.#$unsubscribe),
            )
            .subscribe(() => {
                const randomDelayToRecheckViewportPresence = 200;
                if (this.viewport) {
                    this.viewport?.checkViewportSize();
                } else {
                    // If user resize result list directly after app was started, before than first result was loaded, viewport is
                    // not existing
                    const $stopRecheck = new Subject<void>();
                    const $recheckViewportPresence = timer(randomDelayToRecheckViewportPresence, randomDelayToRecheckViewportPresence).pipe(
                        filter(() => !!this.viewport),
                        takeUntil($stopRecheck),
                    );
                    $recheckViewportPresence.subscribe(() => {
                        $stopRecheck.next();
                        this.viewport?.checkViewportSize();
                    });
                }
            });
    }

    /**
     * We consider prepend if both array ends with the same item
     */
    #isPrepend(itemsA: T[], itemsB: T[]): boolean {
        const lastItemA = _.last(itemsA);
        const lastItemB = _.last(itemsB);
        if (!lastItemA || !lastItemB) {
            return false;
        }

        return itemsA.length !== itemsB.length && this.#getId(lastItemA) === this.#getId(lastItemB);
    }

    #unsubscribe(): void {
        this.#$unsubscribe.next();
        this.#$unsubscribe.complete();
    }

    #scrollToIndex(index: number): void {
        if (this.#$scrolledIndexChange.getValue() === index) {
            return;
        }
        this.viewport?.scrollToIndex(index);
    }

    #getNumberOfItemsVisibleInScroll(): number {
        const heightOfViewport = this.viewport?.elementRef.nativeElement.clientHeight;
        if (heightOfViewport) {
            return Math.ceil(heightOfViewport / this.itemHeightInPx);
        }
        return 1;
    }

    #getId(item: T): string {
        return getId(item);
    }

    #calculateItemHeightInPx(): number {
        const root = document.documentElement;
        const computedStyle = getComputedStyle(root);
        // This scss variable is defined here: libs/common/style/scss/variables.scss
        const heightInRem = parseFloat(computedStyle.getPropertyValue('--journey-list-item-height'));
        const fontSize = parseFloat(computedStyle.fontSize);
        const itemHeightInPx = Math.ceil(fontSize * heightInRem);
        // console.log('fontSize', itemHeightInPx);
        return itemHeightInPx;
    }
}

function getId(item: JourneyAdapter | Placeholder): string {
    if (isPlaceholder(item)) {
        return item.id;
    }
    return item.getId();
}
