import { Component, OnInit } from '@angular/core';
import { ChangePlaceEventSourceEnum, createEmptyArea, GeolocationStatus } from '@traas/boldor/all-models';
import { initialState } from '@traas/boldor/common/features/home/store/endpoint/endpoint.state';
import { BookmarkService } from '@traas/boldor/common/services/common/bookmark/bookmark.service';
import { AppStartupLocationService } from '@traas/boldor/common/services/common/geolocation/app-startup-location.service';
import { AnalyticsService } from '@traas/common/analytics';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        private analyticsService: AnalyticsService,
        private bookmarkService: BookmarkService,
        private appStartupLocationService: AppStartupLocationService,
    ) {
        initialState.departure.area = createEmptyArea(ChangePlaceEventSourceEnum.ManualMapMove);
    }

    async ngOnInit(): Promise<void> {
        await this.initializeApp();
    }

    async initializeApp(): Promise<void> {
        this.appStartupLocationService.emitLocationStatus(GeolocationStatus.Done);
        await this.bookmarkService.initializeBookmarks();
        this.analyticsService.initializeWebTrackingIfEnabled();
    }
}
