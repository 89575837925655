import { SwisspassService } from '@traas/boldor/common/features/swisspass/services/SwisspassService';

export class SwissapssServiceMock implements SwisspassService {
    activateSwissPassMobile(): Promise<boolean> {
        return Promise.resolve(false);
    }

    canActivateSwissPass(): Promise<{ canActivate: boolean; status: string }> {
        return Promise.resolve({ canActivate: false, status: '' });
    }

    forceLogout(): Promise<void> {
        return Promise.resolve(undefined);
    }

    getAccessToken(): Promise<string | null> {
        return Promise.resolve(null);
    }

    getUserInfo(): Promise<null> {
        return Promise.resolve(null);
    }

    isAuthenticatedOnSwissPass(): Promise<boolean> {
        return Promise.resolve(false);
    }

    isSwissPassAvailable(): Promise<boolean> {
        return Promise.resolve(false);
    }

    linkSwissPassToAccount(): Promise<void> {
        return Promise.resolve(undefined);
    }

    logout(): Promise<boolean> {
        return Promise.resolve(false);
    }

    openAccountManagementPage(): Promise<void> {
        return Promise.resolve(undefined);
    }

    openLoginDataManagementPage(): Promise<void> {
        return Promise.resolve(undefined);
    }

    openSwissPassMobile(): Promise<void> {
        return Promise.resolve(undefined);
    }

    openSwisspassLoginPage(): Promise<{ isLoggedIn: boolean }> {
        return Promise.resolve({ isLoggedIn: false });
    }

    registerSwissPass(): Promise<string | null> {
        return Promise.resolve(null);
    }
}
