<h1 class="mb3">Conditions de vente et contrôle des titres de transport</h1>
<div class="sub-title mb3">
    La politique de contrôle des TPC se base sur des règles strictes qui garantissent l’application de la LTV (<a
        href="https://www.admin.ch/opc/fr/classified-compilation/20061345/index.html"
        >Loi fédérale sur le transport de voyageurs</a
    >). Elle assure l’équité de traitement pour tous les clients.
</div>
<h2>Généralités</h2>
<div class="mb3">Des contrôles sporadiques sont effectués sur l’entier du réseau des TPC SA.</div>
<div class="mb3">
    Chaque voyageur doit être muni d’un titre de transport valable avant d’embarquer dans le train ou le bus. Les voyageurs sans titre de
    transport valable devront s’acquitter d’un supplément comprenant une part forfaitaire du prix du voyage.
</div>
<div class="sub-title">Titre de transport électronique</div>
<div class="mb3">
    Les clients doivent être en possession du titre de transport électronique avant le commencement du voyage (départ réel du transport).
    L’achat, respectivement la procédure de commande, doit être totalement terminé avant l’heure de départ réel du transport et l’E-Ticket
    doit être imprimé ou disponible sur l’application de l’appareil correspondant.
</div>
<div class="sub-title">Vélos</div>
<div class="mb3">
    Le voyageur doit posséder pour son vélo un billet demi-tarif jusqu’à concurrence de CHF 14.- et une carte journalière vélo CFF au-delà.
    Le passeport vélo des CFF (abonnement annuel) est valable sur l’entier du réseau TPC.
</div>

<div class="sub-title">Chiens</div>
<div class="mb3">
    Un billet au tarif réduit doit être acquis. Seuls les chiens-guides, pour autant qu’ils possèdent une carte d’identification valable, et
    les animaux de petites tailles (jusqu’à 30 cm d’hauteur d’épaule), transportés dans un panier ou une cage, voyagent gratuitement.
</div>
<h2>Qui doit payer et dans quel cas?</h2>
<div class="mb1">
    Sont considérés comme <span class="bold">voyageurs sans titre de transport valable</span>, les voyageurs sans titre de transport valable
    pour l’intégralité ou pour une partie du voyage. Les voyageurs sans titre de transport ou avec un titre de transport en partie valable
    s’acquittent d’un supplément majoré, <span class="bold">quel que soit le motif invoqué</span> (en retard pour acheter un billet,
    impossibilité de le retrouver, perte du portefeuille, batterie plate sur le smartphone, etc). Par ailleurs, l’identité et les
    coordonnées seront saisies électroniquement et resteront enregistrées pendant un minimum de deux ans. Le titre de transport et le
    supplément seront facturés. Etant donné que les contrôles se font de manière sporadique, il n’est pas possible d’oblitérer sa carte ou
    d’acquérir un billet dans les trains régionaux
</div>
<h2>Tarifs</h2>
<div class="mb1">Vue d’ensemble des suppléments perçus:</div>
<table>
    <tr>
        <th>Voyage sans titre de transport valable</th>
        <th>Coût</th>
    </tr>
    <tr>
        <td>Absence de titre de transport valable, première infraction</td>
        <td>CHF 100.00*</td>
    </tr>
    <tr>
        <td>Absence de titre de transport valable, première récidive</td>
        <td>CHF 140.00*</td>
    </tr>
    <tr>
        <td>Absence de titre de transport valable, dès le troisième cas</td>
        <td>CHF 170.00*</td>
    </tr>
</table>

<div class="legend mb3">*une part forfaitaire de CHF 10.00 pour le prix du voyage est incluse.</div>
<table>
    <tr>
        <th>Voyage avec titre de transport en partie valable</th>
        <th>Coût</th>
    </tr>
    <tr>
        <td>Titre de transport en partie valable, première infraction</td>
        <td>CHF 75.00*</td>
    </tr>
    <tr>
        <td>Titre de transport en partie valable, première récidive</td>
        <td>CHF 115.00*</td>
    </tr>
    <tr>
        <td>Titre de transport en partie valable, dès le troisième cas</td>
        <td>CHF 145.00*</td>
    </tr>
</table>

<div class="legend mb3">*une part forfaitaire de CHF 5.00 pour le prix du voyage est incluse.</div>
<div class="mb1">
    Un voyage avec titre de transport en partie valable est un client présentant un titre de transport valable sur l’ensemble du parcours
    mais dont la validité n’est pas suffisante pour cause de titre de transport destiné à un autre segment de clientèle. Exemples:
</div>
<ul>
    <li>Billet ½ tarif sans l’abonnement ½ tarif</li>
    <li>Défaut de supplément de nuit</li>
    <li>Voyage en 1ère classe avec un billet de 2ème classe</li>
</ul>
<table>
    <tr>
        <th>Abonnement oublié</th>
        <th>Coût</th>
    </tr>
    <tr>
        <td>
            Abonnement oublié : sur présentation d’une pièce d’identité lors du contrôle. Le délai de présentation de l’abonnement et du
            constat d’amende dans une gare TPC est de 10 jours.
        </td>
        <td>CHF 5.00</td>
    </tr>
    <tr>
        <td>Abonnement oublié : Au-delà de 10 jours</td>
        <td>CHF 30.00</td>
    </tr>
</table>

<table>
    <tr>
        <th>E-ticket</th>
        <th>Coût</th>
    </tr>
    <tr>
        <td>
            Dans l’impossibilité de contrôler l’E-Ticket acheté (batterie vide, téléphone portable oublié, impression de l’E-Ticket oubliée,
            E-Ticket illisible/incontrôlable, etc.), des frais de traitement s’appliquent pour le contrôle d’E−Tickets non
            présentés/incontrôlables sur le champ.
        </td>
        <td>CHF 30.00</td>
    </tr>
</table>

<h2>Paiements échelonnés</h2>
<div>
    L’échéance d’une facture ne peut pas être repoussée. Il est cependant possible de conclure un arrangement de paiement d’un montant
    minimum de CHF 20.00 par mois. Pour tout renseignement complémentaire: <a href="mailto:facturation@tpc.ch">facturation&#64;tpc.ch</a>.
    Nous vous avons fait parvenir une facture ou un rappel concernant un voyage sans titre de transport valable. Différents délais sont
    définis pour régler les sommes dues ou pour contester une facture:
</div>
<ul>
    <li><span class="bold">Facture</span> : délai de paiement de <span class="bold">30 jours</span></li>
    <li><span class="bold">Rappel</span> : délai de paiement de <span class="bold">15 jours</span></li>
    <li>
        <span class="bold">Réclamation</span> : délai de
        <span class="bold">30 jours dès réception de la facture</span>
    </li>
</ul>
<h2>Registre national des resquilleurs</h2>
<div class="mb3">
    La base de données centrale regroupant les données des voyageurs sans titre de transport valable ou avec un titre de transport
    partiellement valable a été mise en service par la branche des transports publics le 1er avril 2019. Cette introduction se fonde sur une
    décision du Parlement datant de 2015. Les personnes qui resquillent de manière répétée peuvent dès lors être identifiées plus
    rapidement, la pratique est uniformisée à l’échelon national et les processus sont simplifiés. Vous trouverez
    <a href="https://www.allianceswisspass.ch/fr/La-branche/Mandats-Alliance-SwissPass/Registre-national-des-resquilleurs"
        >ici les principales informations</a
    >
    sur cette base de données.
</div>
<div class="sub-title">Droit de renseignements</div>
<div class="mb1">
    Conformément à la loi fédérale sur la protection des données, tout voyageur peut en tout temps déposer une demande écrite en indiquant
    son nom, son adresse et sa date de naissance afin de savoir si des données existent à son sujet. La personne requérante doit joindre à
    la demande une copie de son passeport ou de sa carte d’identité. La demande de renseignements peut être envoyée directement à CarPostal:
</div>
<address class="mb1">
    CarPostal SA <br />
    «SynServ» <br />
    Pfingstweidstrasse 60b <br />
    8080 Zurich <br />
    <a href="mailto:synserv@postauto.ch">synserv&#64;postauto.ch</a> <br />
</address>
<div>
    Pour plus d’informations sur le Registre national des resquilleurs,
    <a href="https://www.allianceswisspass.ch/fr/La-branche/Mandats-Alliance-SwissPass/Registre-national-des-resquilleurs">cliquez ici</a>
</div>
<h2>Fondements juridiques</h2>
<div class="mb3">
    Les voyageurs sans titre de transport valable ou avec titre de transport en partie valable s’acquittent d’un supplément comprenant une
    part forfaitaire du prix du voyage. Dans tous les cas, leur identité est relevée. Toute dénonciation demeure réservée. En cas d’abus,
    des frais supplémentaires sont dus (au moins CHF 100.00 par personne). De plus, toute poursuite pénale demeure réservée. La présentation
    d’un ticket falsifié implique non seulement des taxes élevées (au moins CHF 200.00), mais aussi des conséquences pénales systématiques.
    Toutes nos décisions s’appuient sur le tarif général des voyageurs T600 et le T600.5 Voyageurs sans titre de transport valable. Ce
    document repose sur la loi du 20 mars 2009 sur le transport de voyageurs (LTV RS 745.1) ainsi que sur l’ordonnance du 4 novembre 2009
    sur le transport des voyageurs (OTV RS 745.11)
</div>
<div class="legend">Sous réserve de modification.</div>
