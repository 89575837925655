import { NgModule } from '@angular/core';
import { TpcCgvComponent } from './tpc-cgv.component';
import { COMPANY_COMPONENTS, DynamicComponentService } from '@traas/boldor/common/services/dynamic-component.service';

@NgModule({
    declarations: [TpcCgvComponent],
    exports: [TpcCgvComponent],
})
export class TpcCgvModule {
    constructor(private dynamicComponentService: DynamicComponentService) {
        this.dynamicComponentService.setComponentType(COMPANY_COMPONENTS.Cgv, TpcCgvComponent);
    }
}
