import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MenuRoutingModule } from './menu-routing.module';
import { MenuPage } from './menu';
import { MenuTpcComponent } from './containers/menu-tpc/menu-tpc.component';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';
import { TicketModule } from '@traas/boldor/common/features/ticket/ticket.module';
import { MenuModule } from '@traas/boldor/common/features/menu/menu.module';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';

@NgModule({
    declarations: [MenuPage, MenuTpcComponent],
    imports: [BoldorFeatureShellSharedModule, TicketModule, PageTitleModule, MenuRoutingModule, IonicModule, MenuModule],
})
export class MenuPageModule {}
