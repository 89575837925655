import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';
import { TpcTosComponent } from './tpc-tos/tpc-tos.component';

@NgModule({
    declarations: [TpcTosComponent],
    imports: [BoldorFeatureShellSharedModule, CommonModule, IonicModule, PageTitleModule],
    exports: [TpcTosComponent],
})
export class TpcFeatureTermsOfServiceModule {}
