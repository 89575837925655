import { LanguageCode } from '@traas/boldor/all-models';
// Ne pas simplifier cet import en ../ car ce fichier est fileReplacé dans un autre contexte où le ../ sera faux au build
import { SwissPassEnvEnum } from '@traas/boldor/environments/specific/SwissPassEnvEnum';
import { TPC_DEFAULT_BOOKMARKS } from '@traas/boldor/environments/specific/tpc/tpc-default-bookmarks';
import { AppConfig, EnvConfig } from '../env.model';

export const tpcAppConfig: AppConfig = {
    appId: 'ch.tpc.appclient',
    currentCommitHash: '69ffd0fdb',
    clientVersion: '37.0.2',
    company: 'tpc',
    languages: [LanguageCode.French],
    iosAppUrl: 'https://itunes.apple.com/ch/app/tpc-mobile/id1441773974?l=fr&mt=8',
    defaultPlace: { latitude: 46.316845, longitude: 6.96368 }, // Aigle gare
    mapTilerUrl: 'https://api.maptiler.com/maps/3461a0b9-0c7e-47be-80dd-3f262bd794dd/{z}/{x}/{y}.png?key=GiwYPfoEMEgJ0Jrs4al9',
    sentryDsn: 'https://4c404580cc7441a784752f78a2cbff8d@o311737.ingest.sentry.io/5293661',
    sentryRelease: 'ch.tpc.appclient@37.0.2',
    version: '37.0.2',
    showNationalMarketingConsentInPreferences: true,
    defaultBookmarks: TPC_DEFAULT_BOOKMARKS,
    guestModeEnabled: false,
    features: {
        showDateItemInJourneysList: true,
        showCancelledJourneysInBookingList: true,
    },
    defaultRefreshIntervals: {
        departureRefreshIntervalInSeconds: 10,
        itineraryRefreshIntervalInSeconds: 20,
        autoScrollRefreshIntervalInSeconds: 30,
    },
    defaultThresholds: {
        minimumThresholdToShowIconInSeconds: 60,
        minutesThresholdToDisplayWaitingMinutes: 60,
    },
    defaultTitles: {
        fr: [
            { label: 'Monsieur', value: 'MAENNLICH' },
            { label: 'Madame', value: 'WEIBLICH' },
        ],
    },
};

export const tpcDeveloperEnvConfig: Omit<EnvConfig, 'environment' | 'gatewayUrl' | 'appSchemeUrl'> = {
    isDebugMode: true,
    showEnvironmentInformations: true,
    logSentry: false,
    traceSentry: false,
    analyticsLoggingEnabled: false,
    analyticsTrackingEnabled: false,
    analyticsUserIdHashSalt: '------------',

    mobileSwisspass: {
        environment: SwissPassEnvEnum.Integration,
        clientId: 'tpc_mobile_inte',
        deeplink: 'tpcmobileinte', // IMPORTANT, when changing this deeplink (TEST -> INT), you must also change the trapeze configuration (config.*.yml)
        provider: 'tpcmobil',
    },
    webSwisspassRedirectUri: 'http://l4200.openit.ch/web-auth-success',
};
