import {
    Departure as GqlDeparture,
    GetRouteQuery,
    JourneyMessageType as JourneyMessageTypeGQL,
} from '@traas/boldor/graphql-generated/graphql';
import { Departure, JourneyEnum, JourneyMessageType, TransitStop } from '@traas/boldor/all-models';
import * as moment from 'moment';
import { ErrorCodes, TechnicalError } from '@traas/common/models';
import { LoggingService } from '@traas/common/logging';
import { parseSyntheseDatetime } from '@traas/common/utils';
import { parse } from 'date-fns';
import { DATE_TIME_FORMAT_DateFns } from '@traas/boldor/all-helpers';

export class GqlToFrontDepartureConverter {
    static toDeparture(departure: GqlDeparture): Departure {
        const {
            stop,
            stop: { physicalStop },
            stop: {
                physicalStop: { associatedCommercialStop },
            },
        } = departure;
        return {
            departureTimestamp: departure.departureTimestamp,
            plannedDepartureTime: departure.plannedDepartureTime,
            direction: departure.direction,
            line: {
                number: departure.line.number,
                networkId: departure.line.networkId,
                lineOfficialCode: departure.line.lineOfficialCode,
                id: departure.line.id,
                fontColor: departure.line.fontColor,
                destination: departure.line.destination,
                color: departure.line.color,
                style: departure.line.style,
            },
            messages: [],
            reservation: false, // @deprecated, to remove
            serviceId: departure.serviceId,
            stop: {
                messages: [],
                cityName: stop.cityName,
                name: stop.name,
                id: stop.id,
                rank: stop.rank,
                isOptional: stop.isOptional,
                physicalStop: {
                    letter: physicalStop.letter,
                    geometry: physicalStop.geometry,
                    id: physicalStop.id,
                    lines: [],
                    transportMode: physicalStop.transportMode,
                    associatedCommercialStop: {
                        id: departure.stop.id,
                        name: associatedCommercialStop.name,
                        cityName: associatedCommercialStop.cityName,
                        coordinates: {
                            accuracy: null,
                            ...associatedCommercialStop.coordinates,
                        },
                        lines: [],
                        didok: associatedCommercialStop.didok,
                    },
                },
            },
            track: departure.track,
            transport: departure.transport,
            networkId: departure.networkId,
            hasStopRequest: departure.hasStopRequest,
            id: departure.id,
            bookingDeadline: departure.bookingDeadline,
            remainingTimeBeforeBooking: departure.remainingTimeBeforeBooking,
            hasBookingRequirements: departure.hasBookingRequirements,
            isBookable: departure.isBookable,
            isCancelled: departure.isCancelled,
            outdatedDate: departure.outdatedDate ? moment(departure.outdatedDate).toDate() : undefined,
            __type__: JourneyEnum.Departure,
        };
    }

    static convertGetRouteStopsToTransitStops(data: GetRouteQuery['route']['stops'][0][], loggingService: LoggingService): TransitStop[] {
        return data.map((externalStop) => {
            const transitStop: TransitStop = {
                coordinates: externalStop.coordinates,
                arrivalTime: externalStop.arrivalTime,
                arrivalDate: externalStop.arrivalDate,
                id: externalStop.id,
                arrivalRealTime: externalStop.arrivalRealTime,
                departureDate: externalStop.departureDate,
                arrivalRealTimeDate: externalStop.arrivalRealTimeDate,
                cityName: externalStop.cityName,
                departureRealTime: externalStop.departureRealTime,
                departureTime: externalStop.departureTime,
                departureRealTimeDate: externalStop.departureRealTimeDate,
                hasBookingRequirements: externalStop.hasBookingRequirements,
                isCancelled: externalStop.isCancelled,
                isOptional: externalStop.isOptional,
                rank: externalStop.rank,
                messages: externalStop.messages.map((externalMessage) => {
                    return {
                        htmlContent: externalMessage?.htmlContent,
                        id: externalMessage?.id,
                        title: externalMessage?.title,
                        type: this.convertJourneyMessageType(externalMessage.type, loggingService),
                    };
                }),
                stopAreaName: externalStop.stopAreaName,
                stopPointName: externalStop.stopPointName,
            };
            return transitStop;
        });
    }

    static convertJourneyMessageType(type: JourneyMessageTypeGQL, loggingService: LoggingService): JourneyMessageType {
        switch (type) {
            case JourneyMessageTypeGQL.Cancellation:
                return JourneyMessageType.Cancellation;
            case JourneyMessageTypeGQL.Disruption:
                return JourneyMessageType.Disruption;
            default:
                loggingService.logError(
                    new TechnicalError('Unexpected JourneyMessageType', ErrorCodes.Graphql.Mapping, undefined, { type }),
                );
                return JourneyMessageType.Disruption;
        }
    }

    static convertToDate(date: string | undefined): Date | undefined {
        if (!date) return undefined;
        return parse(date, DATE_TIME_FORMAT_DateFns, new Date());
    }

    static convertGqlDepartureToDeparture(gqlDeparture: GqlDeparture, loggingService: LoggingService): Departure {
        const outdatedDate = this.convertToDate(gqlDeparture.outdatedDate);
        return {
            ...gqlDeparture,
            __type__: JourneyEnum.Departure,
            reservation: false,
            networkId: gqlDeparture.networkId,
            messages: gqlDeparture.messages.map((message) => {
                return {
                    ...message,
                    type: this.convertJourneyMessageType(message.type, loggingService),
                };
            }),
            outdatedDate,
            stop: {
                ...gqlDeparture.stop,
                messages: gqlDeparture.stop.messages.map((message) => {
                    return {
                        ...message,
                        type: this.convertJourneyMessageType(message.type, loggingService),
                    };
                }),
            },
        };
    }
}
