import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { IonContent, NavController } from '@ionic/angular';
import { MapService } from '@traas/boldor/common/features/map/services/map.service';
import { TosService } from '@traas/common/feature-tos';

@Component({
    selector: 'tpc-accept-tos-page',
    templateUrl: './tpc-accept-tos-page.component.html',
    styleUrls: ['./tpc-accept-tos-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpcAcceptTosPageComponent {
    @ViewChild('checkboxSeparator') checkboxSeparator: ElementRef;

    tosAccepted: boolean;

    constructor(
        private navCtrl: NavController,
        private mapService: MapService,
        private acceptTosService: TosService,
    ) {}

    // noinspection JSUnusedGlobalSymbols
    /**
     * Used to prevent bug in this scenario :
     * Home -> nav to -> My account -> click to input of login form -> the keyboard is opened, but the map on the homepage
     * is with an height of 0. So from this moment, all moveend (mapMoveEnd event from Asymetrik directive) will have a bad bounds
     * (same northEast lat/lng than southWest point). With this, we prevent this bug.
     */
    ionViewWillEnter(): void {
        if (this.mapService.hasMap()) {
            window.setTimeout(() => {
                this.mapService.getMap().invalidateSize();
            });
        }
    }

    async confirmTosAcceptanceAndLaunchApp(): Promise<void> {
        await this.acceptTosService.acceptedTos();
        await this.navCtrl.navigateRoot('/');
    }

    acceptTos(content: IonContent): void {
        this.tosAccepted ? this.confirmTosAcceptanceAndLaunchApp() : content.scrollToBottom(500);
    }
}
