/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticatedGuardFnFactory } from '@traas/boldor/common/guards/is-authenticated.guard';
import { redirectToLoginOnAllReject } from '@traas/common/utils';
import { MenuPage } from './menu';

const routes: Routes = [
    {
        path: '',
        component: MenuPage,
    },
    {
        path: 'customer-service',
        loadChildren: () =>
            import('@traas/boldor/common/pages/customer-service/customer-service.module').then(
                (module) => module.CustomerServicePageModule,
            ),
    },
    {
        path: 'menu-cgu',
        loadChildren: () => import('@traas/boldor/tpc').then((module) => module.TpcDisplayTosPageModule),
    },
    {
        path: 'accessibility',
        loadChildren: () =>
            import('@traas/boldor/common/pages/accessibility/accessibility-page.module').then((module) => module.AccessibilityPageModule),
    },
    {
        path: 'account',
        canActivate: [redirectToLoginOnAllReject(isAuthenticatedGuardFnFactory)],
        loadChildren: () => import('@traas/boldor/common/pages/account/account.module').then((module) => module.AccountPageModule),
    },
    {
        path: 'preferences',
        loadChildren: () =>
            import('@traas/boldor/common/pages/preferences/preferences-page.module').then((module) => module.PreferencesPageModule),
    },
    {
        path: 'frequent-travelers-list',
        canActivate: [redirectToLoginOnAllReject(isAuthenticatedGuardFnFactory)],
        loadChildren: () =>
            import('@traas/boldor/common/pages/frequent-travelers/frequent-travelers-list/frequent-travelers-list-page.module').then(
                (module) => module.FrequentTravelersListPageModule,
            ),
    },
    {
        path: 'credit-cards',
        canActivate: [redirectToLoginOnAllReject(isAuthenticatedGuardFnFactory)],
        loadChildren: () =>
            import('@traas/boldor/common/pages/credit-cards/credit-cards-page.module').then((module) => module.CreditCardsPageModule),
    },
    {
        path: 'login',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('@traas/boldor/tpc').then((module) => module.TpcLoginPageModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MenuRoutingModule {}
