@if ($hasValidDepartures | async) {
  @if (departuresAdapter().length > 0) {
    @if (currentDepartureDatePlaceholder(); as placeholder) {
      <traas-placeholder-list-item
        class="top-placeholder"
        (click)="$event.preventDefault()"
        [placeholder]="placeholder"
      ></traas-placeholder-list-item>
    }
    <traas-infinite-scroll-list
      (scrollManually)="scrollManually.emit()"
      [scrollMode]="scrollMode"
      [itemTemplate]="itemTmpl"
      (requestMore)="onRequestMoreDepartures($event)"
      (requestPrevious)="onRequestPreviousDepartures($event)"
      [items]="departuresAdapter()"
      [showSkeletons]="showSkeletons()"
      (newItemsInViewport)="onNewItemsInViewport($event)"
      >
      <traas-departure-list-item-skeleton
        class="top-skeleton"
        [class.has-arrow]="isTPC"
        skeleton-start
        [hasArrow]="isTPC"
        [noMargin]="isTPG"
      ></traas-departure-list-item-skeleton>
      <traas-departure-list-item-skeleton
        class="bottom-skeleton"
        [class.has-arrow]="isTPC"
        skeleton-end
        [hasArrow]="isTPC"
        [noMargin]="isTPG"
      ></traas-departure-list-item-skeleton>
    </traas-infinite-scroll-list>
  } @else {
    <ion-row>
      <ion-col class="ion-text-center">
        <h3 class="no-departure">{{ 'departures.empty-result' | translate }}</h3>
      </ion-col>
    </ion-row>
  }
} @else {
  <ion-row>
    <ion-col class="ion-text-center">
      <h3 class="no-departure">{{ 'departures.invalid-response' | translate }}</h3>
    </ion-col>
  </ion-row>
}

<ng-template #itemTmpl let-departure>
  @if (!departure.isPlaceholder) {
    @if ($timeDisplayMode | async; as timeDisplayMode) {
      <departure-list-item
        [thresholds]="thresholds"
        [timeDisplayMode]="timeDisplayMode"
        (click)="onDepartureItemClicked(departure)"
        (outdated)="onOutdatedData()"
        [departure]="departure"
        tappable
        >
      </departure-list-item>
    }
  } @else {
    <traas-placeholder-list-item (click)="$event.preventDefault()" [placeholder]="departure"></traas-placeholder-list-item>
  }
</ng-template>


