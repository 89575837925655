import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BoldorFeatureShellSharedModule } from '@traas/boldor/common/boldor-feature-shell-shared.module';
import { CommonModule } from '@angular/common';
import { PageTitleModule } from '@traas/boldor/common/components/page-title/page-title.module';
import { TpcFeatureTermsOfServiceModule } from '../../components/tpc-feature-terms-of-service.module';
import { TpcDisplayTosPageComponent } from './tpc-display-tos-page.component';

const routes: Routes = [
    {
        path: '',
        component: TpcDisplayTosPageComponent,
    },
];

@NgModule({
    declarations: [TpcDisplayTosPageComponent],
    imports: [
        BoldorFeatureShellSharedModule,
        CommonModule,
        IonicModule,
        PageTitleModule,
        TpcFeatureTermsOfServiceModule,
        RouterModule.forChild(routes),
    ],
})
export class TpcDisplayTosPageModule {}
