<ion-accordion-group>
    <ion-accordion>
        <ion-item slot="header">
            <ion-icon slot="start" src="assets/icons/tabs/monochrome/schedule.svg"></ion-icon>
            {{ 'menu.schedules' | translate }}
        </ion-item>
        <ion-list slot="content">
            <ion-item class="e2e-next-departures" (click)="navigateToHome()">{{ 'menu.next-departures' | translate }}</ion-item>
            <ion-item class="e2e-itineraries" (click)="navigateToItinerary()">{{ 'menu.itineraries' | translate }}</ion-item>
            <ion-item class="e2e-favorites" (click)="navigateToFavorites()">{{ 'menu.favorites' | translate }}</ion-item>
        </ion-list>
    </ion-accordion>

    <ion-accordion>
        <ion-item slot="header">
            <ion-icon slot="start" src="assets/icons/tabs/monochrome/tickets.svg"></ion-icon>
            {{ 'menu.buy' | translate }}
        </ion-item>
        <ion-list slot="content">
            <ion-item detail="false" class="e2e-quick-tickets" routerLink="/tabs/tickets">{{ 'menu.quick-tickets' | translate }}</ion-item>
            <ion-item detail="false" class="e2e-my-tickets" routerLink="/tabs/bookings">{{ 'menu.tickets' | translate }}</ion-item>
        </ion-list>
    </ion-accordion>

    <ion-accordion toggle-icon="''">
        <ion-item class="e2e-my-travels" slot="header" routerLink="/tabs/bookings">
            {{ 'menu.journeys' | translate }}
            <ion-icon slot="start" src="assets/icons/tabs/monochrome/journey.svg"></ion-icon>
        </ion-item>
    </ion-accordion>

    <ion-accordion toggle-icon="''">
        <ion-item class="e2e-menu-road-network" slot="header" (click)="navigateToPlans()">
            {{ 'menu.network-map' | translate }}
            <ion-icon slot="start" src="assets/icons/menu/plans.svg"></ion-icon>
        </ion-item>
    </ion-accordion>

    <ion-accordion>
        <ion-item slot="header" class="e2e-my-account">
            <ion-icon slot="start" src="assets/icons/menu/account.svg"></ion-icon>
            {{ 'menu.my-account' | translate }}
        </ion-item>
        <ion-list slot="content">
            <ion-item detail="false" class="e2e-my-profile" routerLink="./account">{{ 'menu.my-profile' | translate }}</ion-item>
            <ion-item detail="false" class="e2e-my-travelers" routerLink="/tabs/menu/frequent-travelers-list"
                >{{ 'menu.my-travelers' | translate }}
            </ion-item>
            <ion-item detail="false" class="e2e-menu-credit-cards" routerLink="/tabs/menu/credit-cards"
                >{{ 'menu.my-credit-cards' | translate }}
            </ion-item>
            <ion-item detail="false" routerLink="/tabs/menu/preferences" class="e2e-menu-preferences"
                >{{ 'menu.preferences' | translate }}
            </ion-item>
        </ion-list>
    </ion-accordion>

    <ion-accordion>
        <ion-item slot="header" class="e2e-help">
            <ion-icon slot="start" src="assets/icons/menu/information.svg"></ion-icon>
            {{ 'menu.help' | translate }}
        </ion-item>
        <ion-list slot="content">
            <ion-item class="e2e-faq" (click)="openFaq()">{{ 'menu.faq' | translate }}</ion-item>
            <ion-item routerLink="./menu-cgu" class="e2e-menu-terms-of-use" detail="false">{{ 'menu.terms-of-use' | translate }} </ion-item>
        </ion-list>
    </ion-accordion>
</ion-accordion-group>
