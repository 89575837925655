import { Bookmark, ChangePlaceEventSourceEnum } from '@traas/boldor/all-models';

export const TPC_DEFAULT_BOOKMARKS: Bookmark[] = [
    {
        boundsRect: {
            southWest: {
                lat: 46.31545178325082,
                lng: 6.962377584628291,
            },
            northEast: {
                lat: 46.31774362453456,
                lng: 6.965496262101399,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Aigle Gare',
            longitude: '6.141701401249045',
            latitude: '46.20965976852618',
        },
        stops: {
            didok: [8501400, 8570600],
            id: ['1970539585342572'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.51626839543857,
                lng: 6.628204667616031,
            },
            northEast: {
                lat: 46.51699862492693,
                lng: 6.629201996585561,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Lausanne-Gare',
            longitude: '6.6270727',
            latitude: '46.5176057',
        },
        stops: {
            didok: [8501120],
            id: ['1970329131942080'],
        },
    },
    {
        boundsRect: {
            southWest: {
                lat: 46.25360594835671,
                lng: 6.947011301601763,
            },
            northEast: {
                lat: 46.25409545206492,
                lng: 6.9476766428768775,
            },
        },
        metadata: {
            source: ChangePlaceEventSourceEnum.ManualMapMove,
            locationName: 'Monthey-Ville, gare',
            longitude: '6.9447287',
            latitude: '46.2536277',
        },
        stops: {
            didok: [8501463, 8579033],
            id: ['1970539585342603'],
        },
    },
];
