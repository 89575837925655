import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TPC_CURRENT_TOS_VERSION } from '../../models/tpc-current-tos-version.model';

@Component({
    selector: 'tpc-tos',
    templateUrl: './tpc-tos.component.html',
    styleUrls: ['./tpc-tos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TpcTosComponent {
    tosVersion = TPC_CURRENT_TOS_VERSION;
}
