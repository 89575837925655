<ion-header>
    <app-page-title iconUrl="assets/icons/menu/account.svg" title="{{ 'login-dispatcher.title' | translate }}"></app-page-title>
</ion-header>

<ion-content>
    <div class="vertically-center-content">
        <div class="vertically-centered-content">
            <common-login>
                <img class="logo" src="assets/logo/tpc-logo.svg" />
                <h6 class="message">{{ 'tpc.login-dispatcher.message' | translate }}</h6>
            </common-login>
        </div>
    </div>
</ion-content>
