import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuService } from '@traas/boldor/common/features/menu/services/menu.service';

const TPC_PLANS_URL = 'https://tpc.ch/plans';

@Component({
    selector: 'menu-tpc',
    templateUrl: './menu-tpc.component.html',
    styleUrls: ['./menu-tpc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuTpcComponent {
    constructor(private menuService: MenuService) {}

    navigateToHome(): Promise<boolean> {
        return this.menuService.navigateToHome();
    }

    navigateToItinerary(): Promise<boolean> {
        return this.menuService.navigateToItinerary();
    }

    navigateToFavorites(): Promise<boolean> {
        return this.menuService.navigateToFavorites();
    }

    async navigateToPlans(): Promise<void> {
        await this.menuService.openWebPageInBrowser(TPC_PLANS_URL);
    }

    async openFaq(): Promise<void> {
        await this.menuService.openWebPageInBrowser('https://faq.tpc.ch/', '_self');
    }
}
