<div class="cgu-header">
    <ion-icon class="header-logo" src="assets/logo/tpc-logo.svg"></ion-icon>
    <h3>Conditions générales de l’application TPC Mobile</h3>
</div>
<div>
    <p>{{ tosVersion }}</p>
    <p class="content">
        Les conditions générales d'utilisation de l'application « TPC Mobile » des Transports Publics du Chablais SA et la politique de
        protection des données des TPC sont soumises à acceptation.
        <br />
        Veuillez prendre connaissance de ces conditions avant acceptation >
        <a href="https://tpc.ch/cguappmobile" target="_blank" rel="noopener">https://tpc.ch/cguappmobile</a>
    </p>
</div>
