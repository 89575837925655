import { Injectable } from '@angular/core';
import { Endpoint, GeolocationStatus, GeopositionAdapter } from '@traas/boldor/all-models';
import { map, take } from 'rxjs/operators';
import { EndpointActions } from '../../../features/home/store/endpoint';
import { LocationWaitingAlert } from '../../../models/async-dismissible-alert';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeolocationService } from './geolocation.service';
import { Store } from '@ngrx/store';
import { AlertService } from '../alert.service';
import { LatLngExpression } from 'leaflet';

@Injectable({
    providedIn: 'root',
})
export class AppStartupLocationService {
    constructor(
        private alertService: AlertService,
        private geolocationService: GeolocationService,
        private store: Store,
    ) {}

    #$initialGeolocationStatus = new BehaviorSubject<GeolocationStatus>(GeolocationStatus.Initial);
    startupGeoposition?: GeopositionAdapter | null;

    emitLocationStatus(status: GeolocationStatus): void {
        this.#$initialGeolocationStatus.next(status);
    }

    $isLocationDone(): Observable<boolean> {
        return this.#$initialGeolocationStatus.pipe(map((status) => status === GeolocationStatus.Done));
    }

    async presentInitialLocationAlert(): Promise<void> {
        console.debug('Startup location ...');
        this.#$initialGeolocationStatus.next(GeolocationStatus.Pending);
        const $position = this.geolocationService.$getPositionUpdates().pipe(take(1));
        const waitingLocationAlert = await this.alertService.buildLocationInitAlert();
        const locationWaitingAlert = new LocationWaitingAlert(waitingLocationAlert, $position);
        locationWaitingAlert.$done.pipe(take(1)).subscribe(({ hasFoundLocation, isClosed, position }) => {
            if (!hasFoundLocation) {
                this.store.dispatch(new EndpointActions.UnfollowGps({ endpoint: Endpoint.Departure }));
                this.store.dispatch(new EndpointActions.SetActiveEndpoint(Endpoint.Departure));
            } else {
                this.startupGeoposition = position;
            }
            if (!isClosed) {
                try {
                    locationWaitingAlert.dismiss();
                } catch (error) {
                    console.warn(`HomeMapContainerService.handleLocationDone: Error while trying to close modal : ${error.message}`);
                }
            }
            this.#$initialGeolocationStatus.next(GeolocationStatus.Done);
        });
        await locationWaitingAlert.present();
    }

    getStartupGeolocationOrDefaultValue(defaultValue: { latitude: number; longitude: number }): LatLngExpression {
        return this.startupGeoposition
            ? {
                  lat: this.startupGeoposition.latitude,
                  lng: this.startupGeoposition.longitude,
              }
            : {
                  lat: defaultValue.latitude,
                  lng: defaultValue.longitude,
              };
    }
}
