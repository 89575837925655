import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeFrCH from '@angular/common/locales/fr-CH';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ApolloClientModule } from '@traas/boldor/common/services/common/apollo/apollo-client.module';
import { WebDatetimePickerModalModule } from '@traas/boldor/common/components/datetime-picker-modal/datetime-picker-modal.module';
import { AngularSplitModule } from 'angular-split';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationApi, HalfFareService, OnAuthenticateService } from '@traas/common/feature-account';
import { PreferencesService } from '@traas/boldor/common/services/common/preferences/preferences.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BoldorFeatureShellCoreModule } from '@traas/boldor/common/boldor-feature-shell-core.module';
import { ItineraryArticleService } from '@traas/boldor/common/features/itinerary/services/itinerary-article.service';
import { ItineraryArticleServiceTpc } from '@traas/boldor/common/features/itinerary/services/itinerary-article.service.tpc';
import { PlaceModule } from '@traas/boldor/common/features/place/place.module';
import { QuickArticleInputService } from '@traas/boldor/common/features/ticket/services/quick-article-input.service';
import { QuickArticleInputServiceTpc } from '@traas/boldor/common/features/ticket/services/quick-article-input.service.tpc';
import { SwisspassAuthenticationApiService } from '@traas/boldor/common/services/common/authentication/swisspass-authentication-api.service';
import { TraasOnAuthenticateService } from '@traas/boldor/common/services/common/authentication/traas-on-authenticate.service';
import { ConfigurationService } from '@traas/boldor/common/services/common/configuration/configuration.service';
import { CustomerProviderService } from '@traas/boldor/common/services/common/customer/customer-provider.service';
import { SwisspassCustomerProviderService } from '@traas/boldor/common/services/common/customer/swisspass-customer-provider.service';
import { GlobalErrorHandler } from '@traas/boldor/common/services/common/errors/global.error-handler';
import { environment } from '@traas/boldor/environments';
import { TpcPreferencesService } from '@traas/boldor/tpc';
import { AnalyticsConfig } from '@traas/common/analytics';
import { BoldorLocalizationService } from '@traas/boldor/localization';
import { LOG_REMOTE } from '@traas/common/logging';
import { RoutingService } from '@traas/common/routing';
import { TraasHalfFareService } from '@traas/boldor/common/services/common/customer/traas-half-fare.service';
import { SWISSPASS_SERVICE_TOKEN } from '@traas/boldor/common/features/swisspass/services/SwisspassService';
import { CartService } from '@traas/boldor/common/services/common/cart/cart.service';
import { SwissapssServiceMock } from './swisspass-service.mock';
import { AccountManagementService } from '@traas/boldor/common/services/common/customer/account-management.service';

registerLocaleData(localeFrCH);

// Modules which needs to be loaded first and in the right order
const orderSensitiveModules = [
    BrowserModule,
    BrowserAnimationsModule,
    PlaceModule, //this is important to be before the AppRoutingModule or it will break the /#/ in path
    AppRoutingModule,
    IonicModule.forRoot({
        scrollPadding: true,
        scrollAssist: true,
        hardwareBackButton: false,
        innerHTMLTemplatesEnabled: true,
    }),
    BoldorFeatureShellCoreModule.forRoot(),
];

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        ...orderSensitiveModules,
        AngularSplitModule,
        ApolloClientModule,
        WebDatetimePickerModalModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
                deps: [HttpClient],
            },
            defaultLanguage: 'fr',
        }),
        ReactiveFormsModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    preferencesService: PreferencesService,
                    boldorLocalizationService: BoldorLocalizationService,
                    configService: ConfigurationService,
                ) =>
                (): Promise<void> => {
                    return preferencesService
                        .initializeDefaultPreferences()
                        .then(() => configService.assertConfiguration())
                        .then(() => boldorLocalizationService.init());
                },
            deps: [PreferencesService, BoldorLocalizationService, ConfigurationService],
            multi: true,
        },
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: LOCALE_ID,
            useFactory: (boldorLocalizationService: BoldorLocalizationService): string => boldorLocalizationService.localeId,
            deps: [BoldorLocalizationService],
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        RoutingService,
        AccountManagementService,
        {
            provide: AnalyticsConfig,
            useValue: {
                isTrackingEnabled: environment.analyticsTrackingEnabled,
                isLoggingEnabled: environment.analyticsLoggingEnabled,
                userIdHashSalt: environment.analyticsUserIdHashSalt,
                webAnalyticsConfig: environment.analyticsWebConfig,
            },
        },
        {
            // le code ci-dessous est un hack pour éviter que le service SwisspassService soit instancié
            // dans cette app mode web, le SWISSPASS_SERVICE_TOKEN peut être mocké
            provide: SWISSPASS_SERVICE_TOKEN,
            useClass: SwissapssServiceMock,
        },
        // le CartService est injecté dans ItineraryDetailsContainer, mais inutile dans l'app mode web
        { provide: CartService, useValue: null },
        { provide: AuthenticationApi, useClass: SwisspassAuthenticationApiService },
        { provide: LOG_REMOTE, useValue: environment.logSentry },
        { provide: QuickArticleInputService, useClass: QuickArticleInputServiceTpc },
        { provide: ItineraryArticleService, useClass: ItineraryArticleServiceTpc },
        { provide: PreferencesService, useClass: TpcPreferencesService },
        { provide: OnAuthenticateService, useClass: TraasOnAuthenticateService },
        { provide: HalfFareService, useClass: TraasHalfFareService },
        { provide: CustomerProviderService, useClass: SwisspassCustomerProviderService },
    ],
    exports: [AppComponent],
})
export class AppModule {}
