import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncDismissibleAlert, GeopositionAdapter, LocationAlertResult } from '@traas/boldor/all-models';

const timeoutDelay = 10000;

export class LocationWaitingAlert {
    $done: Subject<LocationAlertResult> = new Subject();
    $timeoutLocation: Observable<number>;

    constructor(
        private asyncAlert: AsyncDismissibleAlert<HTMLIonAlertElement>,
        private $locationFound: Observable<GeopositionAdapter | null>,
    ) {
        this.$timeoutLocation = timer(timeoutDelay).pipe(takeUntil(this.$done));
    }

    async present(): Promise<void> {
        await this.asyncAlert.alert.present();
        this.asyncAlert.$dismissed.pipe(takeUntil(this.$done)).subscribe(() => {
            this.#done({ hasFoundLocation: false, isClosed: true });
        });
        this.$locationFound.pipe(takeUntil(this.$done)).subscribe((position) => {
            this.#done({ hasFoundLocation: !!position, isClosed: false, position });
        });
        this.$timeoutLocation.pipe(takeUntil(this.$done)).subscribe(() => {
            this.#done({ hasFoundLocation: false, isClosed: false });
        });
    }

    dismiss(): void {
        this.asyncAlert.alert.dismiss();
    }

    #done(result: LocationAlertResult): void {
        this.$done.next(result);
        this.$done.complete();
    }
}
